var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about"}},[_c('header',[_c('div',{staticClass:"headerTop"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("首页")])],1),_c('li',[_c('router-link',{attrs:{"to":"wisdom"}},[_vm._v("智量百业")])],1),_c('li',[_c('router-link',{attrs:{"to":"shop"}},[_vm._v("同城好店")])],1),_c('li',{staticClass:"cur"},[_c('router-link',{attrs:{"to":"assistant"}},[_vm._v("抖微助手")])],1),_c('li',[_c('router-link',{attrs:{"to":"flyfishoutlets"}},[_vm._v("飞鱼系统")])],1),_c('li',[_c('router-link',{attrs:{"to":"commerce"}},[_vm._v("跨境电商")])],1),_c('li',[_c('router-link',{attrs:{"to":"about"}},[_vm._v("关于我们")])],1)])])]),_c('img',{attrs:{"src":require("@/assets/images/assistant_banner1.jpg"),"alt":""}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h1',[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd100 assistant_1"},[_c('div',{staticClass:"title"},[_c('h4',[_c('img',{attrs:{"src":require("@/assets/images/assistant-2.png"),"alt":""}})]),_c('p',[_vm._v("抖音关键词精准引流")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"assistant-bg"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-bg-1.jpg"),"alt":""}})]),_c('div',{staticClass:"item item_1"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-1.jpg"),"alt":""}}),_c('p',[_vm._v("智能养号")])]),_c('div',{staticClass:"item item_2"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-2.jpg"),"alt":""}}),_c('p',[_vm._v("精准引流")])]),_c('div',{staticClass:"item item_3"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-3.jpg"),"alt":""}}),_c('p',[_vm._v("线索分析")])]),_c('div',{staticClass:"item item_4"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-4.jpg"),"alt":""}}),_c('p',[_vm._v("智能截流")])]),_c('div',{staticClass:"item item_5"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-5.jpg"),"alt":""}}),_c('p',[_vm._v("客服聊天")])]),_c('div',{staticClass:"item item_6"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-icon-6.jpg"),"alt":""}}),_c('p',[_vm._v("添加微信")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd100 assistant"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("抖音关键词精准引流")])]),_c('div',{staticClass:"main"},[_c('ul',[_c('li',[_c('p',[_vm._v("独创高效引流系统")]),_c('p',[_vm._v("独创裂变引流系统，1变5、5变25指数级裂变")])]),_c('li',[_c('p',[_vm._v("抖音智能养号涨粉")]),_c('p',[_vm._v("分享引流自动锁客，各级数据清晰明了")])]),_c('li',[_c('p',[_vm._v("抖音精准客户变现")]),_c('p',[_vm._v("搭建私域流量池，变现涨粉相互协同")])])]),_c('img',{attrs:{"src":require("@/assets/images/assistant-1.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container pd100 shop_2"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("整体解决方案详情")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/assistant-3.jpg"),"alt":""}}),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据客户需求，设定IP人设，智能养号")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据甲方行业特点和用户需求，24小时持续 "),_c('br'),_vm._v("收集潜在意向客户 ")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据行业特点，对潜在客户进行推广，引导 "),_c('br'),_vm._v("用户反馈和关注 ")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据推广情况，对精准用户进行在线转化变现")])])])]),_c('div',{staticClass:"item"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据行业和用户画像，结合大数据系统学习 "),_c('br'),_vm._v("优化搜索关键词，精准收集潜在意向客户。 ")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("定时定向精准同行粉丝转化，事半功倍。")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("提供行业实战话术模板，以及即时私信聊天 "),_c('br'),_vm._v("功能，实现精准线索的高效转化。 ")])])]),_c('img',{attrs:{"src":require("@/assets/images/assistant-4.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd60 contact"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("业务合作")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/images/contact-2.png"),"alt":""}}),_c('p',[_vm._v(" 请将合作资料发送至邮箱：1487986688@qq.com 或者加微信：13410419943 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"main container pd60"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v("关于我们")]),_c('p',[_vm._v("公司介绍")]),_c('p',[_vm._v("业务合作")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("联系我们")]),_c('p',[_vm._v("邮箱：1487986688@qq.com")]),_c('p',[_vm._v("电话（微信同名）：13410419943，周一至周日 9:30 - 22:30")])]),_c('div',{staticClass:"wx"},[_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-1.jpg"),"alt":""}}),_c('p',[_vm._v("扫码关注公众号")])]),_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-2.jpg"),"alt":""}}),_c('p',[_vm._v("业务合作")])])])]),_c('div',{staticClass:"copyright container pd60"},[_c('p',[_vm._v("©2019-2024 深圳漠阳风科技有限公司 版权所有")]),_c('a',{attrs:{"href":"http://beian.miit.gov.cn/"}},[_vm._v("粤ICP备19157531号-2")])])])
}]

export { render, staticRenderFns }